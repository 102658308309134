import { useLocation } from '@gatsbyjs/reach-router'
import { Grid } from '@material-ui/core'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import { ProtocolResourceDocument, ProtocolResourceQuery } from '../../../../graphql/api'
import { Dispensary } from '../../../../graphql/camel'
import { useApiQuery } from '../../../../hooks/useApiQuery'
import useLogAndCaptureError from '../../../../hooks/useLogAndCaptureError'
import { addDispensaryBasePathToPath } from '../../../../lib/dispensaries'
import { useDispensaryByCodeEager } from '../../../../lib/dispensaries/hooks/useDispensaryByCodeEager'
import { buildResourceAssetUrl, libraryUrl } from '../../../../lib/library'
import { usePractitionerSettingsEager } from '../../../../lib/practitioner-settings/hooks/usePractitionerSettingsEager'
import ContactSupportMessage from '../../../ContactSupportMessage'
import FullPageError from '../../../FullPageError'
import ClickToCopy from '../../../global/ClickToCopy'
import Link from '../../../global/Link'
import Spacer from '../../../Spacer'
import Spinner from '../../../Spinner'
import { Product } from '../Product'
import ProtocolAddToCart from '../ProtocolAddToCart'
import * as Styled from './styled'

const DispensaryNotFoundMessage: React.FC = () => (
  <Grid item xs={12}>
    <Styled.DispensaryNotFoundMessage>
      Your Virtual Dispensary is currently not set up. Please reach out to our Customer Experience
      team at <Styled.PhoneNumber href="tel:18606236314">860-623-6314</Styled.PhoneNumber> for
      assistance. Once your account is all set up, you will be able to prescribe protocols to your
      patients using generated eScript links.
    </Styled.DispensaryNotFoundMessage>
  </Grid>
)

interface DispensaryEScriptLinkProps {
  dispensary: Dispensary
  products: NonNullable<ProtocolResourceQuery['protocolResource']>['products']
}

const DispensaryEScriptLink: React.FC<DispensaryEScriptLinkProps> = React.memo(
  ({ dispensary, products }) => {
    const location = useLocation()
    const productsString = products?.map(
      (product) => `(${product?.product?.sku}*${product?.quantity})`,
    )
    const productsParams = productsString?.join('')
    const versionParams = 'v=2023-04-11'
    const paramsString = `${versionParams}&products=${productsParams}`
    const scriptParams = new URLSearchParams(paramsString)
    const link = `${location.origin}${addDispensaryBasePathToPath(
      dispensary.slug,
      'checkout',
    )}?${scriptParams}`
    return <ClickToCopy copyText={link} />
  },
)

DispensaryEScriptLink.displayName = 'DispensaryEScriptLink'

interface EScriptLinkProps {
  products: NonNullable<ProtocolResourceQuery['protocolResource']>['products']
}

const EScriptLink: React.FC<EScriptLinkProps> = ({ products }) => {
  const {
    data: settings,
    loading: settingsLoading,
    error: settingsError,
  } = usePractitionerSettingsEager()
  useLogAndCaptureError(settingsError)
  const practitionerCode = settings?.practitioner?.partnerCode
  const {
    loading: dispLoading,
    data: dispensary,
    error: dispError,
  } = useDispensaryByCodeEager({
    practitionerCode,
    skip: !!settings?.practitioner?.optedOutOfDispensary,
  })
  useLogAndCaptureError(dispError)

  return settingsLoading || dispLoading ? (
    <Spinner loading />
  ) : dispensary ? (
    <DispensaryEScriptLink dispensary={dispensary} products={products} />
  ) : (
    <DispensaryNotFoundMessage />
  )
}

export interface ProtocolProps {
  slug: string
}

export const Protocol: React.FC<ProtocolProps> = ({ slug }) => {
  const { called, loading, data, error } = useApiQuery(ProtocolResourceDocument, {
    variables: { slug },
    skipWithoutToken: true,
  })
  useLogAndCaptureError(error)

  const protocol = data?.protocolResource
  const bodyRaw = protocol?.bodyRaw

  if (!called || loading) {
    return (
      <div>
        <Helmet title="Loading Protocol" />
        <Spinner loading />
      </div>
    )
  }

  if (error) {
    return (
      <FullPageError>
        <Helmet title="Error" />
        An error occurred retrieving data, and we have been notified.
        <small>
          <ContactSupportMessage />
        </small>
        <small>
          <Link to={libraryUrl}>Go back to Library</Link>
        </small>
      </FullPageError>
    )
  }

  if (!protocol) {
    return (
      <FullPageError>
        <Helmet title="Protocol not found" />
        <Styled.MagnifyingGlass /> Protocol Not Found
        <small>
          <Link to={libraryUrl}>Go back to Library</Link>
        </small>
      </FullPageError>
    )
  }

  const hasAdjunctProducts = !!(protocol?.productsAdjunct && protocol.productsAdjunct.length > 0)

  return (
    <Styled.Container data-cy="protocol-page">
      <Helmet title={protocol?.title || 'Protocol'} />
      <Styled.PageHero
        title={protocol?.title || 'Protocol'}
        isTitleTopLevelHeader={false}
        heroImage="dfh-pattern-gray"
        heroTag={{
          tag: `Designs for Health has been the health care professional's trusted source since 1989.`,
        }}
      />
      <Styled.TopNav>
        <Link to={libraryUrl}>Go back to Library</Link>
      </Styled.TopNav>
      <Styled.PaddedContainer>
        <Styled.MaxWidthGrid container spacing={10}>
          <Grid container item xs={12}>
            {bodyRaw && <Styled.BlockContent blocks={bodyRaw} />}
          </Grid>
          {protocol?.products && protocol.products.length > 0 && (
            <React.Fragment>
              <Grid item xs={12}>
                <Styled.SectionTitle>Share this link with your patients</Styled.SectionTitle>
              </Grid>
              <Grid item xs={12}>
                <EScriptLink products={protocol?.products} />
              </Grid>
              <Spacer size={4} />
              <Styled.Row>
                <Styled.Heading>
                  Products in this Protocol{hasAdjunctProducts ? ' (Primary Support)' : ''}
                </Styled.Heading>
                <Styled.ViewProtocolButton
                  as={'a'}
                  href={buildResourceAssetUrl('protocol', slug)}
                  target="_blank"
                >
                  View Protocol Document
                </Styled.ViewProtocolButton>
              </Styled.Row>
              <Grid component="main" item xs={12}>
                <Styled.ItemsContainer>
                  {protocol?.products?.map((product) => {
                    if (!product || !product?.product) return null
                    return <Product product={product} key={product?.product?.sku} />
                  })}
                  <ProtocolAddToCart
                    products={protocol?.products}
                    hasAdjunctProducts={hasAdjunctProducts}
                  />
                </Styled.ItemsContainer>
              </Grid>
            </React.Fragment>
          )}
          {hasAdjunctProducts && (
            <React.Fragment>
              <Grid item xs={12}>
                <Styled.SectionTitle>
                  Products in this Protocol (Adjunct Support)
                </Styled.SectionTitle>
              </Grid>
              <Grid component="main" item xs={12}>
                <Styled.ItemsContainer>
                  {protocol?.productsAdjunct?.map((productAdjunct) => {
                    if (!productAdjunct || !productAdjunct?.product) return null
                    return <Product product={productAdjunct} key={productAdjunct?.product?.sku} />
                  })}
                </Styled.ItemsContainer>
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12}>
            <Styled.ViewProtocolButton
              as={'a'}
              href={buildResourceAssetUrl('protocol', slug)}
              target="_blank"
            >
              View Protocol Document
            </Styled.ViewProtocolButton>
          </Grid>
        </Styled.MaxWidthGrid>
      </Styled.PaddedContainer>
    </Styled.Container>
  )
}
