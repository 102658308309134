import { useCallback, useEffect, useRef, useState } from 'react'

export const useClipboardCopy = ({
  text,
  onCopy,
}: {
  text: string
  onCopy?: (text: string) => void
}) => {
  const [copied, setCopied] = useState(false)
  const previousCopyTextRef = useRef('')

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(text).then(() => {
      if (text !== previousCopyTextRef.current) {
        onCopy?.(text)
      }
      previousCopyTextRef.current = text
      setCopied(true)
    })
  }, [text, onCopy])

  useEffect(() => {
    if (previousCopyTextRef.current === text) {
      return
    }
    setCopied(false)
  }, [text])

  const resetCopied = useCallback(() => {
    setCopied(false)
  }, [])

  return {
    copied,
    copyToClipboard,
    resetCopied,
  }
}
