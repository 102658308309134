import type { RouteComponentProps } from '@gatsbyjs/reach-router'
import React, { FC, useEffect } from 'react'

import { useAuth } from '../../../lib/auth'
import { libraryUrl } from '../../../lib/library'
import { Protocol } from './Protocol'

export type ProtocolRouteProps = RouteComponentProps<{ slug: string }>

export const ProtocolRoute: FC<ProtocolRouteProps> = ({ slug }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth()

  useEffect(() => {
    if (!slug || isAuthenticated || isLoading) {
      return
    }
    ;(async (): Promise<void> => {
      await loginWithRedirect({
        appState: {
          returnTo: `${libraryUrl}/protocol/${slug}`,
        },
      })
    })()
  }, [isAuthenticated, isLoading, loginWithRedirect, slug])

  if (!slug || isLoading) {
    return null
  }

  return <Protocol slug={slug} />
}
