import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'

import { ProtocolResourceQuery } from '../../../graphql/api'
import { SearchExactParentOrChildSkuDocument } from '../../../graphql/search'
import useLogAndCaptureError from '../../../hooks/useLogAndCaptureError'
import { TokenContext } from '../../../layouts/context'
import { ProductEntry } from './ProductEntry'

export interface ProductProps {
  className?: string
  product: NonNullable<NonNullable<ProtocolResourceQuery['protocolResource']>['products']>[number]
}

export const Product: React.FC<ProductProps> = ({ className, product }) => {
  const token = useContext(TokenContext)
  const quantity = product?.quantity
  const sku = product?.product?.sku
  const { data, error } = useQuery(SearchExactParentOrChildSkuDocument, {
    context: {
      token,
      uri: process.env.GATSBY_SEARCH_URL,
    },
    variables: {
      sku: sku,
    },
    skip: !sku,
  })
  useLogAndCaptureError(error)
  if (!sku || !quantity) return null
  return (
    <div className={className}>
      {data?.exact?.results?.map((entry) => (
        <ProductEntry item={entry} key={sku} itemSku={sku} itemQuantity={quantity} />
      ))}
    </div>
  )
}
