import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { FC, useEffect } from 'react'

import { libraryUrl } from '../../../lib/library'
import { usePageUrl } from '../../../lib/pages'

export type ProtocolRootRouteProps = RouteComponentProps<{ slug: string }>

// NOTE: used this method instead of a `<Redirect />` because we need to redirect outside of
// the `<Router />` base path
export const ProtocolRootRoute: FC<ProtocolRootRouteProps> = () => {
  const { navigate } = usePageUrl()
  useEffect(() => {
    navigate(libraryUrl)
  }, [navigate])
  return null
}
