import { ApolloError } from '@apollo/client'

import {
  GetCustomerSettingsPractitionerDocument,
  GetCustomerSettingsPractitionerQuery,
} from '../../../graphql/camel'
import useCamelQuery from '../../../hooks/useCamelQuery'
import type { PractitionerSettings } from '../types'
import { practitionerSettingsFromResponse } from '../utils'

export interface UsePractitionerSettingsEagerOptions {
  settings?: PractitionerSettings
}

export type UsePractitionerSettingsEager = {
  data: PractitionerSettings | undefined
  loading: boolean
  error: ApolloError | Error | undefined
}

export const usePractitionerSettingsEager = (
  options?: UsePractitionerSettingsEagerOptions,
): UsePractitionerSettingsEager => {
  const settings = options && options.settings ? options.settings : undefined

  const { data, loading, error } = useCamelQuery<GetCustomerSettingsPractitionerQuery>(
    GetCustomerSettingsPractitionerDocument,
    {
      skip: !!settings,
    },
  )

  if (settings) {
    return { data: settings, loading: false, error: undefined }
  }

  const loadedSettings = data ? practitionerSettingsFromResponse(data) : undefined
  return { data: loadedSettings, loading, error }
}
