import React from 'react'
import styled from 'styled-components'

type Size = 0 | 1 | 2 | 3 | 4 | 5

const StyledSpacer = styled.div<{ $size: Size }>`
  overflow: hidden;
  width: 100%;
  padding-top: ${({ $size }) => ($size > 0 ? `${0.25 * Math.pow(2, $size - 1) * 16}px` : `0`)};
`
export interface SpacerProps {
  size: Size
}

const Spacer: React.FC<SpacerProps> = ({ size }) => <StyledSpacer $size={size} />

export default Spacer
