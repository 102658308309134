import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import { Router } from '@gatsbyjs/reach-router'
import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import styled, { css } from 'styled-components'

import useUserCategory from '../../../hooks/useUserCategory'
import Head from '../../../layouts/Head'
import { useAuth } from '../../../lib/auth'
import { isUserCategoryProfessional, libraryUrl } from '../../../lib/library'
import { usePageUrl } from '../../../lib/pages'
import { Error } from '../../Error'
import Layout from '../../global/Layout'
import Spinner from '../../Spinner'
import { ProtocolRootRoute } from './ProtocolRootRoute'
import { ProtocolRoute } from './ProtocolRoute'

const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.grayscale.white};
  `}
`

export const ProtocolPage: FC = () => {
  const { getPagePath, navigate } = usePageUrl()
  const { isAuthenticated, isLoading, error } = useAuth()
  const { userCategory } = useUserCategory()
  const isProfessional = isUserCategoryProfessional(userCategory)

  useEffect(() => {
    if (!isLoading && isAuthenticated && !isProfessional) {
      navigate(libraryUrl)
    }
  }, [isLoading, isAuthenticated, isProfessional, navigate])

  return (
    <>
      <Head title="Protocol" />
      <GlobalStyle includeTypography />
      <Layout>
        <Container>
          {(isLoading || !isAuthenticated) && (
            <>
              <Helmet title="Loading" />
              <Spinner loading />
            </>
          )}
          {error && (
            <>
              <Helmet title="Error" />
              <Error message={error.message} />
            </>
          )}
          <Router basepath={`${getPagePath(`${libraryUrl}/protocol`)}`}>
            <ProtocolRootRoute path="/" />
            <ProtocolRoute path="/:slug" />
          </Router>
        </Container>
      </Layout>
    </>
  )
}
